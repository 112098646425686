import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import "./App.css";
import { IntlProvider } from "react-intl";
import {
  APP_BASE_ROUTE,
  ROUTE_CAMERA,
  ROUTE_DEPLOYMENT_LIST,
  ROUTE_CUSTOMER,
  ROUTE_CUSTOMER_LIST,
  ROUTE_DEPLOYMENT,
  ROUTE_DEPLOYMENT_MOSAIC,
  ROUTE_LANDING,
  ROUTE_USER_PROFILE,
  ROUTE_USER_MANAGEMENT,
  ROUTE_DEPLOYMENT_GROUP_MOSAIC,
  ROUTE_VIEW_LINK_MANAGEMENT,
  ROUTE_TEMPORARY_VIEWER
} from "./utils/routes";
import { ErrorBoundary } from "./components/shared/errorBoundaryComponent/ErrorBoundaryComponent";
import LandingComponent from "./components/landingComponent/LandingComponent";
import ErrorComponent from "./components/shared/errorComponent/ErrorComponent";
import NotificationSnack from "./components/shared/notificationSnack/NotificationSnack";
import GlobalLoadingComponent from "./components/globalLoadingComponent/GlobalLoadingComponent";
import CustomerListComponent from "./components/customerListComponent/CustomerListComponent";
import DeploymentComponent from "./components/deploymentComponent/DeploymentComponent";

import TopMenuComponent from "./components/topMenuComponent/TopMenuComponent";

import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import messages_gl from "./translations/gl.json";
import FooterComponent from "./components/common/footerComponent/FooterComponent";

import { useDispatch, useSelector } from "react-redux";
import {
  setPermissionsActionCreator,
  setStylesActionCreator,
  setManageableUserRolesActionCreator
} from "./actions/commonActions";
import { getUserRoles, loadUserProfile } from "./services/commonServices";
import {getTenantData, hasRoles} from "./utils/keycloak";
import {
  BLANK_STYLE,
  DISABLED,
  GRAY,
  ROLES_ADMIN,
  LOCAL_STORAGE__LOCALE,
  ROLE_STARTS_WITH,
  KEYCLOAK_TENANT_CLIENTIDS,
  TENANT_NAME_DEFAULT,
  LOCAL_STORAGE__CUSTOMER_STYLES
} from "./utils/constants";
import { useKeycloak } from "@react-keycloak/web";
import DeploymentListComponent from "./components/deploymentListComponent/DeploymentListComponent";
import Favicon from "react-favicon";
import UserManagementComponent from "./components/userManagementComponent/UserManagementComponent";
import ProfileComponent from "./components/profileComponent/ProfileComponent";
import CameraMosaicContainer from "./components/cameraMosaicComponent/cameraMosaicContainer/CameraMosaicContainer";
import {
  getCurrentTenant,
  getLanguage,
  getTenantFromUrl,
  setCurrentTenant,
  urlHasTenant
} from "./utils/utils";
import { buildUrl } from "./utils/axiosRequests";
import ViewLinkManagementComponent from "./components/viewLinkComponent/ViewLinkManagementComponent";
import TemporaryViewerComponent from "./components/temporaryViewerComponent/TemporaryViewerComponent";

const messages = {
  es: messages_es,
  en: messages_en,
  gl: messages_gl
};

const App = () => {
  const language = localStorage.getItem(LOCAL_STORAGE__LOCALE) || getLanguage();
  const [locale, setLocale] = useState({
    locale: language,
    messages: messages[language]
  });
  const isAdmin = hasRoles(ROLES_ADMIN);
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const currentTenant = getTenantFromUrl();
  const localStorageCustomerStyles = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE__CUSTOMER_STYLES)
  );

  const customerStylesRedux = useSelector(
    state => state.commonState.customerStyles
  );

  const [customerStyles, setCustomerStyles] = useState(
    customerStylesRedux ? customerStylesRedux : {}
  );

  useEffect(() => {
    if (!!keycloak && !!initialized) {
      const roles = keycloak?.realmAccess?.roles;
      const currentUserRole = roles?.find(role =>
        role.startsWith(ROLE_STARTS_WITH)
      );
      if (!!currentUserRole) {
        getUserRoles(
          currentUserRole,
          getUserRolesCallback,
          getUserRolesErrorCallback
        );
      }
      if (keycloak.authenticated && !isAdmin) {
        loadUserProfile(keycloak);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized, isAdmin, dispatch]);

  const getUserRolesCallback = response => {
    dispatch(setPermissionsActionCreator(response.data.permissions));
    dispatch(
      setManageableUserRolesActionCreator(response.data.manageableUserRoles)
    );
  };

  const getUserRolesErrorCallback = () => {
    dispatch(setPermissionsActionCreator([]));
  };

  useEffect(() => {
    if (customerStylesRedux) {
      if (customerStylesRedux.isDefault) {
        dispatch(setStylesActionCreator(customerStylesRedux));
        setCustomerStyles({});
      } else {
        dispatch(setStylesActionCreator(customerStylesRedux));
        setCustomerStyles(customerStylesRedux);
      }
      localStorage.setItem(
        LOCAL_STORAGE__CUSTOMER_STYLES,
        JSON.stringify({ ...customerStylesRedux, tenant: getTenantFromUrl() })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerStylesRedux]);

  const theme = createTheme({
    palette: {
      primary: {
        main:
          customerStyles?.ui_theme?.primary_color?.main ||
          localStorageCustomerStyles?.ui_theme?.primary_color?.main ||
          BLANK_STYLE.ui_theme.primary_color.main,
        light:
          customerStyles?.ui_theme?.primary_color?.light ||
          localStorageCustomerStyles?.ui_theme?.primary_color?.light ||
          BLANK_STYLE.ui_theme.primary_color.light,
        dark:
          customerStyles?.ui_theme?.primary_color?.dark ||
          localStorageCustomerStyles?.ui_theme?.primary_color?.dark ||
          BLANK_STYLE.ui_theme.primary_color.dark
      },
      secondary: {
        dark:
          customerStyles?.ui_theme?.secondary_color?.dark ||
          localStorageCustomerStyles?.ui_theme?.secondary_color?.dark ||
          BLANK_STYLE.ui_theme.primary_color.main,
        light:
          customerStyles?.ui_theme?.secondary_color?.light ||
          localStorageCustomerStyles?.ui_theme?.secondary_color?.light ||
          BLANK_STYLE.ui_theme.primary_color.main,
        main:
          customerStyles?.ui_theme?.secondary_color?.main ||
          localStorageCustomerStyles?.ui_theme?.secondary_color?.main ||
          BLANK_STYLE.ui_theme.primary_color.main
      },
      header_data:
        customerStyles?.header_data ||
        localStorageCustomerStyles?.header_data ||
        BLANK_STYLE.header_data,
      disabled: {
        light: DISABLED,
        main: GRAY
      },
      white: {
        main: "#ffffff"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          ".MuiButton-label": {
            "& p": {
              marginTop: 5
            }
          }
        }
      }
    }
  });

  const handleLanguageChanged = lang => {
    if (messages[lang]) {
      setLocale({ locale: lang, messages: messages[lang] });
    }
  };

  useEffect(() => {
    if (!getCurrentTenant()) {
      setCurrentTenant(getTenantFromUrl() || TENANT_NAME_DEFAULT);
    } else if (getTenantFromUrl() !== getCurrentTenant()) {
      if (KEYCLOAK_TENANT_CLIENTIDS.hasOwnProperty(getTenantFromUrl())) {
        setCurrentTenant(getTenantFromUrl());
        keycloak.logout();
      } else if (!!getTenantFromUrl()) {
        const newUrl = window.location.href.replace(
          getTenantFromUrl(),
          TENANT_NAME_DEFAULT
        );
        keycloak.logout({ redirectUri: newUrl });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant]);

  const getTenantByClientId = (clientId) => {
    for (const [tenant, id] of Object.entries(KEYCLOAK_TENANT_CLIENTIDS)) {
      if (id === clientId) {
        return tenant;
      }
    }

    return TENANT_NAME_DEFAULT;
  }

  useEffect(() => {
    if (!urlHasTenant()) {
      const { clientId } = getTenantData();

      const tenant = getTenantByClientId(clientId);

      window.location.href = `${window.location.href}/${tenant}`;
    }
  }, []);

  return (
    <>
      <Favicon url={customerStyles?.favicon} />
      <IntlProvider
        locale={locale.locale}
        messages={locale.messages}
        defaultLocale="es"
      >
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            <ErrorBoundary key="landingErrorBoundary">
              <Router
                basename={buildUrl(APP_BASE_ROUTE, {
                  tenant: getCurrentTenant() ?? TENANT_NAME_DEFAULT
                })}
              >
                <TopMenuComponent
                  handleLanguageChanged={handleLanguageChanged}
                  language={language}
                />
                <Switch>
                  <Route exact path={ROUTE_LANDING}>
                    <LandingComponent />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT_LIST}>
                    <DeploymentListComponent />
                  </Route>
                  <Route exact path={ROUTE_CUSTOMER_LIST}>
                    <CustomerListComponent />
                  </Route>
                  <Route exact path={ROUTE_CUSTOMER}>
                    <DeploymentListComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT}>
                    <DeploymentComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT_MOSAIC}>
                    <CameraMosaicContainer />
                  </Route>
                  <Route exact path={ROUTE_DEPLOYMENT_GROUP_MOSAIC}>
                    <CameraMosaicContainer />
                  </Route>
                  <Route exact path={ROUTE_CAMERA}>
                    <CameraMosaicContainer />
                  </Route>
                  <Route exact path={ROUTE_USER_PROFILE}>
                    <ProfileComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_USER_MANAGEMENT}>
                    <UserManagementComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_VIEW_LINK_MANAGEMENT}>
                    <ViewLinkManagementComponent locale={locale} />
                  </Route>
                  <Route exact path={ROUTE_TEMPORARY_VIEWER}>
                    <TemporaryViewerComponent />
                  </Route>
                  <Route path="*">
                    <ErrorComponent error="error_404" />
                  </Route>
                </Switch>
                <FooterComponent />
              </Router>
            </ErrorBoundary>
            <NotificationSnack />
            <GlobalLoadingComponent />
          </div>
        </MuiThemeProvider>
      </IntlProvider>
    </>
  );
};

export default App;
