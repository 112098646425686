import format from 'date-fns/format';
import adapter from "webrtc-adapter";
import {
    I18N_CONFIG,
    LOCAL_STORAGE__TENANT,
    OS_ANDROID,
    OS_IOS,
    OS_LINUX,
    OS_MAC,
    OS_WIN, SUPPORTED_TENANTS,
    SVG_URI, TENANT_NAME_DEFAULT,
} from "./constants";
import {showMessage} from "../components/shared/notificationSnack/NotificationSnack";
import {MAX_PAN_TILT_VALUE} from "../components/common/ptz/PtzConstants";
import {
    DEFAULT_SVG_COLOR,
    DEFAULT_SVG_STROKE_WIDTH
} from "../components/common/mouseControlComponent/MouseControlConstants";
import messages_es from "../translations/es.json";
import messages_en from "../translations/en.json";
import messages_gl from "../translations/gl.json";

export const isFunction = (obj) => !!(obj && obj.constructor && obj.call && obj.apply);

export const isString = (string) => typeof (string) === 'string' || string instanceof String;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatTime = (timeline) => {
    const date = new Date(timeline * 1000);
    const hours = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
    const minutes = date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds = date.getSeconds() >= 10 ? date.getSeconds() : `0${date.getSeconds()}`;
    return `${hours}:${minutes}:${seconds}`;
};

export const formatDurationTime = duration => {
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const seconds = Math.floor((duration / 1000) % 60);

    const formatHours = hours < 10 ? `0${hours}` : hours;
    const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formatSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formatHours}:${formatMinutes}:${formatSeconds}`;
};

export const checkTimeDistanceInSeconds = (date1, date2, difference) => (date1 - date2) <= difference;

export const createFullDate = (date, time) => {
    if (!date || !time) {
        return new Date();
    }
    // TIME IN FORMAT: 't'
    const formatTime = format(+time, 'HH:mm:ss');
    const timeArray = formatTime.split(':');
    // DATE IN FORMAT: 'YYYY-mm-dd'
    const dateArray = date.split('-');
    return new Date(dateArray[0], +dateArray[1] - 1, dateArray[2], timeArray[0], timeArray[1], timeArray[2]);
};

export const formatFullDate = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1) >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate();
    const time = formatTime(format(date, 't'));

    return `${time} - ${day}/${month}/${year}`;
};

export const compareArraysObjectByAttribute = (arr1, arr2, attribute) => {

    const string1 = JSON.stringify(arr1.concat().sort((a, b) => {
        if (a[attribute] > b[attribute]) {
            return 1;
        }
        if (a[attribute] < b[attribute]) {
            return -1;
        }
        return 0;
    }));
    const string2 = JSON.stringify(arr2.concat().sort((a, b) => {
        if (a[attribute] > b[attribute]) {
            return 1;
        }
        if (a[attribute] < b[attribute]) {
            return -1;
        }
        return 0;
    }));


    return string1 === string2;
};

export const getOfficialLanguage = (language) => {
    switch (language) {
        case 'gl':
            return 'gl_ES';
        case 'en':
            return 'en_US';
        default :
            return 'es';
    }
};

export const getTimeConversionFn = (seconds, intl) => {
    let secs = seconds % 60;
    seconds = (seconds - secs) / 60;
    let min = seconds % 60;
    seconds = (seconds - min) / 60;
    let hrs = seconds % 24;
    let days = (seconds - hrs) / 24;
    return days + intl.formatMessage({id: 'time_days'}) + ' ' + hrs + intl.formatMessage({id: 'time_hours'}) + ' ' + min +
        intl.formatMessage({id: 'time_minutes'});
};

export const isObject = value => {
    if (value === null) {
        return false;
    }
    return ((typeof value === 'function') || (typeof value === 'object'));
};

export function getBrowser() {
    return adapter.browserDetails.browser;
}

export function getOS() {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = OS_MAC;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = OS_IOS;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = OS_WIN;
    } else if (/Android/.test(userAgent)) {
        os = OS_ANDROID;
    } else if (!os && /Linux/.test(platform)) {
        os = OS_LINUX;
    }
    return os;
}

export const transformPanTiltZoom = (value, speed) => {
    return Math.round(value * speed);
};

export const onSuccess = response => {
    console.debug('Request successful', response);
};

export const onError = error => {
    showMessage(error);
};

export const generateSvgLine = (x1, x2, y1, y2, x, y, radius, id) => {
    const line = document.createElementNS(SVG_URI, 'line');
    line.setAttribute('x1', x1);
    line.setAttribute('x2', x2);
    line.setAttribute('y1', y1);
    line.setAttribute('y2', y2);
    line.setAttribute('stroke', DEFAULT_SVG_COLOR);
    line.setAttribute('strokeWidth', DEFAULT_SVG_STROKE_WIDTH);
    line.setAttribute('id', id);
    x && line.setAttribute('x', x);
    y && line.setAttribute('y', y);
    radius && line.setAttribute('radius', radius);

    line.removeLine = () => {
        line.remove();
    };

    return line;
};

export const generateSvgPolyline = (points, transform, id) => {
    const polyline = document.createElementNS(SVG_URI, 'polyline');
    polyline.setAttribute('points', points);
    polyline.setAttribute('transform', transform);
    polyline.setAttribute('stroke', DEFAULT_SVG_COLOR);
    polyline.setAttribute('strokeWidth', DEFAULT_SVG_STROKE_WIDTH);
    polyline.setAttribute('fill', DEFAULT_SVG_COLOR);
    polyline.setAttribute('id', id);

    polyline.removePolyline = () => {
        polyline.remove();
    };

    return polyline;
}

export const formatPanTiltValue = value => {
    let formattedValue;

    const isPositive = value >= 0;

    if (isPositive) {
        formattedValue = value / 3 <= MAX_PAN_TILT_VALUE ? value / 3 : MAX_PAN_TILT_VALUE;
    } else {
        formattedValue = value / 3 >= -MAX_PAN_TILT_VALUE ? value / 3 : -MAX_PAN_TILT_VALUE;
    }

    return Math.round(formattedValue);
};

const messages = {
    es: messages_es,
    en: messages_en,
    gl: messages_gl
  };

export const getLanguage = () => {
    const navigatorLang = navigator.language.split(/[-_]/)[0];
    return messages[navigatorLang] ? navigatorLang : I18N_CONFIG.es.title;
  };

export const getCurrentTenant = () => {
    return localStorage.getItem(LOCAL_STORAGE__TENANT);
}

export const setCurrentTenant = (tenant) => {
    localStorage.setItem(LOCAL_STORAGE__TENANT, tenant);
}

export const urlHasTenant = () => {
    const urlPath = window.location.pathname;
    const urlParts = urlPath.split('/');

    const index = urlParts.indexOf(process.env.REACT_APP_BASE_ROUTE);

    const tenant = urlParts[index + 1];

    return !(index === -1 || !SUPPORTED_TENANTS.includes(tenant));
}

export const getTenantFromUrl = () => {
    const urlPath = window.location.pathname;
    const urlParts = urlPath.split('/');

    const index = urlParts.indexOf(process.env.REACT_APP_BASE_ROUTE);

    const tenant = urlParts[index + 1];

    if (index === -1 || !SUPPORTED_TENANTS.includes(tenant)) {
        return TENANT_NAME_DEFAULT;
    }

    return tenant;
}

export const formatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatDateTime = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
};

export const orderJsonAlphabetically = (jsonFile) => {
    const translations = {}

    const orderedKeys = Object.keys(jsonFile).sort(function (a, b) {
        if (a > b) {
            return 1
        }
        if (b > a) {
            return -1
        }
        return 0
    })

    for (const key of orderedKeys) {
        translations[key] = jsonFile[key]
    }

    return JSON.stringify(translations)
}
