import React from 'react';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';
import {IconButton, withStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {getStyles} from './MuteStyles';

const useStyles = makeStyles((theme) => getStyles(theme));

const MuteComponent = ({
                           mute, handleMute, displayControls, camera
                       }) => {
    const classes = useStyles();

    return (
        <div className={`${classes.controlsContainer} ${displayControls ? classes.show : classes.hide}`}>
            {mute ? (
                    <IconButton className={classes.playerButton} onClick={() => handleMute(camera, mute)}>
                        <VolumeOffRoundedIcon/>
                    </IconButton>)
                : (
                    <IconButton className={classes.playerButton} onClick={() => handleMute(camera, mute)}>
                        <VolumeUpRoundedIcon/>
                    </IconButton>
                )}
        </div>
    );
};

MuteComponent.propTypes = {
    mute: PropTypes.bool.isRequired,
    handleMute: PropTypes.func.isRequired,
    camera: PropTypes.string,
    displayControls: PropTypes.bool.isRequired,
};

export default withStyles(getStyles)(MuteComponent);
