import Keycloak from "keycloak-js";
import {
  KEYCLOACK_DEFAULT_CLIENTID, KEYCLOACK_DEFAULT_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_TENANT_CLIENTIDS, KEYCLOAK_TENANT_URL,
} from "./constants";
import { getTenantFromUrl } from "./utils";

export const getTenantData = () => {
  const tenantMatch = getTenantFromUrl();
  if (tenantMatch && KEYCLOAK_TENANT_CLIENTIDS.hasOwnProperty(tenantMatch)) {
    return {
      url: KEYCLOAK_TENANT_URL[tenantMatch],
      clientId: KEYCLOAK_TENANT_CLIENTIDS[tenantMatch]
    }
  }
  return {
    url: KEYCLOACK_DEFAULT_URL,
    clientId: KEYCLOACK_DEFAULT_CLIENTID
  };
};

const tenantData = getTenantData();

const keycloakConfig = {
  url: tenantData.url,
  realm: KEYCLOAK_REALM,
  clientId: tenantData.clientId,
};

const keycloak = new Keycloak(keycloakConfig);

export const keycloakInitConfig = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: `${window.location.origin}/vs/silent-check-sso.html`,
  pkceMethod: "S256"
};

export const hasRole = role =>
  keycloak
    ? keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role)
    : false;

export const hasRoles = roles =>
  keycloak
    ? roles.some(r => keycloak.hasRealmRole(r) || keycloak.hasResourceRole(r))
    : false;

export default keycloak;
